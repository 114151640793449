import React from 'react';

// routes
import { withRouter } from 'react-router-dom';

const ItemInforme = ({ data }) => {

    if(data.articulo){
        return (
            <tr>
                <th scope="row">{data.articulo.idArticulo}</th>
                <td>{data.articulo.nombre}</td>
                <td>{data.articulo.precioFinal}</td>
                <td>{Number.parseFloat(data.cantidad / 1000).toFixed(2)}</td>
            </tr>
        );
    }else{
        return (
        <tr>
            <th scope="row">{data.medioPago.idMedioPago}</th>
            <td>{data.medioPago.nombre}</td>
            <td>{data.monto}</td>
        </tr>
    );
    }

    
}

export default withRouter(ItemInforme);